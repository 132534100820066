.App {
  text-align: center;
  align-items: center;
}

.App-logo {
  height: 4vmin;
  pointer-events: none;
  margin-top: 25px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo img {
    height: auto;
    max-width: 100px;
  }
}

.percImg {
  height: auto;
  max-width: 600px;
}
.perceptron {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
}
.regtext {
  font-size: small;
  color: black;
}
.App-header {
  background-color: #eef4f5;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(30px + 2vmin);
  color: rgb(129, 219, 11);
}
.imageCard {
  width: auto;

  object-fit: cover;
  @media only screen and (min-width: 600px) {
    body {
      max-height: 150px;
    }
  }
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 15px; */
}
.regtext {
  text-align: center;
}
.scroll {
  margin: 4px, 4px;
  padding: 4px;

  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}
